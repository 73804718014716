<template>
  <section
    class="w-full  h-full text-center text-white flex justify-center items-center relative coverImage">
    <div class="relative z-30 flex flex-col items-center">
      <h1 class="text-7xl font-bold text-white">
        404
      </h1>
      <h2 class="text-5xl text-white">
        Page Not Found
      </h2>
      <router-link
        class="mt-16 bg-indigo-gradiant rounded-full text-white text-xl font-bold px-16 py-3 "
        to="/">Go Home
      </router-link>
    </div>
  </section>
</template>

<script>
  export default {
    name: "NotFound",
  };
</script>
